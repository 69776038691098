/* WorkInProgress.css */

.work-in-progress-container {
    text-align: center;
    margin-top: 50px;
  }
  
  h1 {
    font-size: 2em;
    color: #333; /* Adjust the color as needed */
  }
  
  p {
    font-size: 1.2em;
    color: #555; /* Adjust the color as needed */
    margin-top: 20px;
  }
  